// src/utils/authStateManager.js
export class AuthStateManager {
  static isCleanupInProgress = false;
  
  static async cleanupAuth() {
    if (this.isCleanupInProgress) return;
    this.isCleanupInProgress = true;
    
    try {
      // Clear only specific auth items, preserving refresh tokens
      const authKeys = Object.keys(localStorage).filter(key => 
        (key.includes('auth0') && !key.includes('refresh')) || 
        key === 'authToken'
      );
      
      authKeys.forEach(key => localStorage.removeItem(key));
      
      // Clear auth-specific session storage
      const sessionAuthKeys = Object.keys(sessionStorage).filter(key =>
        key.includes('auth0') && !key.includes('refresh')
      );
      
      sessionAuthKeys.forEach(key => sessionStorage.removeItem(key));
      
      // Clear only auth-related cookies
      document.cookie.split(";").forEach(cookie => {
        if (cookie.includes('auth0') || cookie.includes('theaissist')) {
          const name = cookie.split("=")[0].trim();
          if (!name.includes('refresh')) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
          }
        }
      });
    } finally {
      this.isCleanupInProgress = false;
    }
  }

  static async prepareForLogin() {
    await this.cleanupAuth();
    return new Promise(resolve => setTimeout(resolve, 100));
  }
}