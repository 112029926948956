import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getNavigatorApi } from '../Api';
import { navigatorMessages } from './navigatorMessagesSlice';
import { handleAuthError } from '../utils/handleAuthError';

const navigatorSlice = createSlice({
  name: 'navigator',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    navigatorRequest(state) {
      state.loading = true;
    },
    navigatorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    navigatorFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { navigatorRequest, navigatorSuccess, navigatorFailure } = navigatorSlice.actions;

export const navigator = (payload) => async (dispatch) => {
  const { input } = payload;
  dispatch(navigatorRequest());
  const token = localStorage.getItem('authToken');

  try {
    await axios.post(
      getNavigatorApi(),
      { input },
      { 
        headers: { 'Authorization': `Bearer ${token}` }
      }
    );
    
    dispatch(navigatorSuccess());
    await dispatch(navigatorMessages());
    
    const pollInterval = setInterval(async () => {
      const response = await dispatch(navigatorMessages());
      if (!response?.status?.navigator_busy) {
        clearInterval(pollInterval);
      }
    }, 2000);

    setTimeout(() => clearInterval(pollInterval), 300000);
    
  } catch (error) {
    if (!handleAuthError(error)) {
      dispatch(navigatorFailure(error.message));
      throw error;
    }
  }
};

export default navigatorSlice.reducer; 