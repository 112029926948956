import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLogsApi } from '../Api';
import { fetchData } from './logSlice';
import { handleAuthError } from '../utils/handleAuthError';

const logDeleteSlice = createSlice({
  name: 'logDelete',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    setLogDeleteRequest(state) {
      state.loading = true;
    },
    setLogDeleteSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    setLogDeleteFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { setLogDeleteRequest, setLogDeleteSuccess, setLogDeleteFailure } = logDeleteSlice.actions;

export const logDelete = (payload) => async (dispatch, getState) => {
  const path = payload || ''

  if(path){
    const token = localStorage.getItem('authToken');

    try {
      const response = await axios.delete(`${getLogsApi()}/${path}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      dispatch(setLogDeleteSuccess(response.data));
    } catch (error) {
      if (!handleAuthError(error)) {
        dispatch(setLogDeleteFailure(error.message));
      }
    }
    dispatch(fetchData());
  }
};

export default logDeleteSlice.reducer;
