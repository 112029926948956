/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthStateManager } from '../utils/authStateManager';
import { setBaseUrl } from '../config/ApiConfig';
import { getEnvironmentConfig } from '../config/environment';

const AuthProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
  const [emailSaved, setEmailSaved] = useState(false);

  useEffect(() => {
    const initializeUser = async () => {
      if (isAuthenticated && !isLoading && user?.email && !emailSaved) {
        try {
          const config = getEnvironmentConfig(user.email);
          
          const token = await getAccessTokenSilently({
            audience: config.audience,
            scope: 'openid profile email'
          });
          
          localStorage.setItem('authToken', token);
          setBaseUrl(user.email);

          try {
            // Use the correct API URL for the save-email call
            const response = await fetch(`${config.apiUrl}/save-email`, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });

            if (response.ok) {
              console.log('Email saved successfully');
              setEmailSaved(true);
            } else {
              const errorData = await response.json();
              console.error('Failed to save email:', errorData);
              
              // Fallback to original OAuth flow if needed
              const loginResponse = await fetch(`${config.apiUrl}/login`, {
                headers: {
                  'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
              });

              if (loginResponse.ok) {
                console.log('Email saved through OAuth flow');
                setEmailSaved(true);
              } else {
                console.error('Both save attempts failed');
              }
            }
          } catch (error) {
            console.error('Error saving email:', error);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          await AuthStateManager.cleanupAuth();
        }
      }
    };

    initializeUser();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, user, emailSaved]);

  useEffect(() => {
    if (!isAuthenticated) {
      setEmailSaved(false);
    }
  }, [isAuthenticated]);

  return children;
};

export default AuthProvider;