import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaPause, FaChevronLeft, FaFileAlt, FaEdit, FaSave, FaCompass } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { kill } from '../features/killSlice';
import { summary } from '../features/summarySlice';
import { viewResearch } from '../features/viewResearchSlice';
import { logEdit } from '../features/logEditSlice';
import { chatMessages } from '../features/chatMessagesSlice';
import { useKillShortcut } from '../hooks/useKillShortcut';
import Navigator from './Navigator';
import { navigator } from '../features/navigatorSlice';

const ViewResearch = () => {
  const { loading, data: viewResearchResponse } = useSelector(state => state.viewResearch) || {};
  const dispatch = useDispatch();
  const [researchText, setResearchText] = useState('');
  const [editable, setEditable] = useState(false);
  const textContainerRef = useRef(null);
  const navigate = useNavigate();

  const { name = '', path = '' } = useSelector(state => state.namePath);
  const { data: viewResearchPath } = useSelector(state => state.createQuestion) || {};
  const { data: chatMessagesResponse } = useSelector(state => state.chatMessages) || {};

  const handleTextCopy = useCallback(async () => {
    try {
      if (textContainerRef.current) {
        const textToCopy = editable 
          ? textContainerRef.current.value 
          : textContainerRef.current.textContent;
        await window.navigator.clipboard.writeText(textToCopy || researchText || '');
      }
    } catch (err) {
      console.error('Failed to copy text:', err);
      // Fallback method for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = researchText || '';
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        textArea.remove();
      } catch (e) {
        console.error('Fallback copy failed:', e);
      }
    }
  }, [editable, researchText]);

  const toggleEdit = useCallback(() => {
    setEditable(!editable);
    if(editable){
      dispatch(logEdit({path: viewResearchPath || path, content:researchText}))
    }
  }, [editable, dispatch, viewResearchPath, path, researchText]);

  const handleNavigator = useCallback((input) => {
    const currentPath = viewResearchPath || path;
    dispatch(navigator({ 
      input,
      page: "viewResearch",
      id: currentPath
    }));
    setIsNavigatorOpen(true);
  }, [dispatch, viewResearchPath, path]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.altKey && e.key === 'n') {
        handleNavigator('');
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleNavigator]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Save - Ctrl/Cmd + S
      if ((e.metaKey || e.ctrlKey) && e.code === 'KeyS') {
        e.preventDefault();
        if (editable) {
          toggleEdit();
        }
        return;
      }

      // Edit - Shift + E
      if (e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altKey && e.code === 'KeyE') {
        e.preventDefault();
        toggleEdit();
        return;
      }

      // Copy - Shift + C
      if (e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altKey && e.code === 'KeyC') {
        e.preventDefault();
        handleTextCopy();
        return;
      }

      // Toggle Navigator - Ctrl/Cmd + Alt + N
      if ((e.ctrlKey || e.metaKey) && e.altKey && e.code === 'KeyN') {
        e.preventDefault();
        setIsNavigatorOpen(prev => !prev);
        return;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [editable, toggleEdit, handleTextCopy]);

  useEffect(() => {
    const pollStatus = () => {
      dispatch(chatMessages());
    };

    // Initial poll
    pollStatus();

    // Set up polling interval
    const intervalId = setInterval(pollStatus, 2000);

    // Cleanup
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    setAliceBusy(
      chatMessagesResponse?.status?.busy || 
      chatMessagesResponse?.status?.summary_busy || 
      false
    );
  }, [chatMessagesResponse?.status]);

  useEffect(() => {
    setResearchText(viewResearchResponse);
  }, [viewResearchResponse]);

  const handleKill = () => {
    dispatch(kill());
    setKillDone(true);
  };

  const handleScrollLeft = () => {
    navigate('/home');
  };

  const handleSummarize = () => {
    dispatch(summary({ name, path }));
    const researchPath = viewResearchPath || path;
    dispatch(viewResearch({ viewResearchPath: researchPath }));
  };

  const handleTextChange = (e) => {
    setResearchText(e.target.value);
  };

  const [aliceBusy, setAliceBusy] = useState(false);
  const [killDone, setKillDone] = useState(false);
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const mainContentRef = useRef(null);

  const handleReportClick = () => {
    setShowReportModal(true);
  };

  const handleConfirmReport = () => {
    setShowReportModal(false);
    handleSummarize();
  };

  useKillShortcut({
    isEnabled: aliceBusy && !killDone,
    onKill: () => setKillDone(true)
  });

  return (
    <div className="flex flex-col h-screen bg-background text-text">
      <header className="flex justify-between items-center p-4 bg-background-light">
        <div className="flex items-center space-x-4">
          <button
            className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
            onClick={handleScrollLeft}
          >
            <FaChevronLeft className="mr-2" />
            Back to Home
          </button>
        </div>

        <div className="flex items-center space-x-4">
          <button
            onClick={() => handleNavigator('')}
            className="p-2 hover:bg-background-light rounded-lg transition-colors duration-200 flex items-center space-x-2"
            title="Open Assistant (Ctrl/Cmd + Alt + N)"
          >
            <FaCompass className="text-text" />
            <span className="text-text">Assistant</span>
          </button>
          <button
            className={`bg-secondary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary ${
              editable ? 'bg-secondary-dark' : ''
            }`}
            onClick={toggleEdit}
          >
            {editable ? (
              <>
                <FaSave className="inline-block mr-2" /> Save
              </>
            ) : (
              <>
                <FaEdit className="inline-block mr-2" /> Edit
              </>
            )}
          </button>
        </div>
      </header>
      <main 
        ref={mainContentRef}
        className={`flex-grow p-6 overflow-auto transition-[margin] duration-300 ease-in-out ${
          isNavigatorOpen ? 'mr-96' : ''
        }`}
      >
        <div
          className="w-full border border-background-lighter p-6 mb-6 overflow-x-auto bg-background-light rounded-lg shadow-md"
          style={{ height: 'calc(100% - 140px)' }}
        >
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="spinner"></div>
            </div>
          ) : editable ? (
            <textarea
              ref={textContainerRef}
              className="w-full h-full bg-background-lighter text-text border-none rounded p-4 focus:outline-none focus:ring-2 focus:ring-primary font-mono text-sm"
              value={researchText}
              onChange={handleTextChange}
            />
          ) : (
            <p ref={textContainerRef} className="whitespace-pre-line text-text leading-relaxed">
              {researchText}
            </p>
          )}
        </div>
        <div className="flex justify-between">
          <button
            className={`btn ${
              killDone || !aliceBusy ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-700'
            }`}
            onClick={handleKill}
            disabled={killDone || !aliceBusy}
          >
            <FaPause className="mr-2" />
            Kill
          </button>
          <button
            className="btn"
            onClick={handleTextCopy}
          >
            <FiCopy className="inline-block mr-2" /> Copy Research
          </button>
          <button
            className={`bg-primary text-white font-bold px-6 py-3 rounded-lg shadow-lg hover:bg-primary-dark transform hover:scale-105 transition-all duration-300 flex items-center justify-center space-x-2 ${
              aliceBusy ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleReportClick}
            disabled={aliceBusy}
          >
            <FaFileAlt className="text-lg" />
            <span>Produce Report</span>
          </button>
        </div>
      </main>
      {isNavigatorOpen && (
        <Navigator
          isOpen={isNavigatorOpen}
          onClose={() => setIsNavigatorOpen(false)}
          page={"viewResearch"}
          id={viewResearchPath || path}
        />
      )}
      {showReportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background-light p-6 rounded-lg shadow-xl max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Confirm Report Generation</h2>
            <p className="mb-6">Are you sure you want to generate a report? WARNING: This operation cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowReportModal(false)}
                className="px-4 py-2 bg-background-lighter text-text rounded-lg hover:bg-background-light transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmReport}
                className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewResearch;