import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowUp, FaTimes } from 'react-icons/fa';
import { navigator } from '../features/navigatorSlice';
import { navigatorMessages } from '../features/navigatorMessagesSlice';

const Navigator = ({ isOpen, onClose, page, id }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const [navigatorBusy, setNavigatorBusy] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [responseMessages, setResponseMessages] = useState([]);
  const previousMessageCountRef = useRef(0);
  const isFirstRenderRef = useRef(true);

  const { data: navigatorMessagesResponse } = useSelector(state => state.navigatorMessages) || {};

  useEffect(() => {
    if (isOpen && page !== 'viewResearch') {
      // Initial poll
      const pollMessages = async () => {
        const response = await dispatch(navigatorMessages());
        const newBusyState = response?.status?.navigator_busy ?? false;
        setNavigatorBusy(!!newBusyState);
      };
      
      pollMessages();

      // Set up interval polling
      const intervalId = setInterval(pollMessages, 2000);

      return () => clearInterval(intervalId);
    }
  }, [dispatch, isOpen, page]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
      setShouldAutoScroll(isAtBottom);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (!navigatorMessagesResponse?.messages) return;

    const newMessages = navigatorMessagesResponse.messages;
    const currentMessageCount = newMessages.length;
    
    // Only update messages if they've actually changed
    const hasNewMessages = currentMessageCount > previousMessageCountRef.current;
    const shouldUpdate = isFirstRenderRef.current || hasNewMessages;
    
    if (shouldUpdate) {
      const messages = newMessages.map(msg => ({
        text: msg.message,
        sender: msg.from,
        timestamp: msg.timestamp,
        id: msg.id || null,
      }));
      setResponseMessages(messages);
      
      // Only auto-scroll if we're already at the bottom or it's the first render
      if ((shouldAutoScroll || isFirstRenderRef.current) && hasNewMessages) {
        requestAnimationFrame(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        });
      }
      
      previousMessageCountRef.current = currentMessageCount;
      isFirstRenderRef.current = false;
    }
  }, [navigatorMessagesResponse?.messages, shouldAutoScroll]);

  useEffect(() => {
    if (isOpen && messagesEndRef.current) {
      requestAnimationFrame(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
      });
    }
  }, [isOpen]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const timeString = date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: '2-digit'
    });
    return `${dateString} ${timeString}`;
  };

  const handleSendMessage = async () => {
    if (!input.trim() || navigatorBusy) return;
    
    try {
      const payload = { input };
      if (page === 'viewResearch' && id) {
        payload.page = page;
        payload.id = id;
      }
      await dispatch(navigator(payload));
      setInput('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 right-0 w-96 z-50">
      <div className="h-full bg-background shadow-xl flex flex-col">
        <div className="flex justify-between items-center p-4 border-b border-background-lighter">
          <h2 className="text-xl font-semibold text-text">Assistant</h2>
          <button onClick={onClose} className="text-text-muted hover:text-text">
            <FaTimes />
          </button>
        </div>
        
        <div className="flex-grow overflow-auto p-4" ref={messagesContainerRef}>
          {responseMessages.map((message, index) => (
            <div
              key={index}
              className={`mb-2 p-2 rounded shadow max-w-md ${
                message.sender === 'user'
                  ? 'bg-background text-text self-start'
                  : 'bg-primary text-text self-end'
              }`}
            >
              <p className="text-sm whitespace-pre-wrap">
                {message.sender === 'user' 
                  ? (() => {
                      try {
                        const parsed = JSON.parse(message.text);
                        return parsed.input;
                      } catch {
                        return message.text;
                      }
                    })()
                  : message.text}
              </p>
              <p className="text-xs text-text-muted mt-1">
                {formatTimestamp(message.timestamp)}
              </p>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <div className="p-4 border-t border-background-lighter">
          <div className="relative">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              placeholder={navigatorBusy ? "Assistant is thinking..." : "Type your message..."}
              disabled={navigatorBusy}
              className={`w-full pr-10 pl-3 py-2 bg-background-light text-text rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                navigatorBusy ? 'cursor-not-allowed opacity-50' : ''
              }`}
            />
            <button
              onClick={handleSendMessage}
              disabled={!input.trim() || navigatorBusy}
              className={`absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-text rounded-full transition-colors duration-200 ${
                !input.trim() || navigatorBusy
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-primary/10'
              }`}
            >
              <FaArrowUp />
            </button>
          </div>
          {navigatorBusy && (
            <div className="mt-2 text-sm text-text-muted animate-pulse">
              Assistant is processing your request...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigator;