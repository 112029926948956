import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { kill } from '../features/killSlice';
import { killQuestion } from '../features/killQuestionSlice';

export const useKillShortcut = ({ 
  isQuestion = false, 
  isEnabled = false, 
  onKill = () => {} 
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyK' && isEnabled) {
        e.preventDefault();
        if (isQuestion) {
          dispatch(killQuestion());
        } else {
          dispatch(kill());
        }
        onKill();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [dispatch, isEnabled, isQuestion, onKill]);
}; 