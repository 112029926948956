import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getPromptApi, getQuestionApi } from '../Api';
import { chatMessages } from './chatMessagesSlice';
import { questionMessages } from './questionMessagesSlice';

const promptSlice = createSlice({
  name: 'promptSlice',
  initialState: {
    loading: false,
    data: [],
    error: '',
    lastRefreshAttempt: null,
    refreshAttempts: 0,
  },
  reducers: {
    promptRequest(state) {
      state.loading = true;
      state.lastRefreshAttempt = null;
      state.refreshAttempts = 0;
    },
    promptSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    promptFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    },
    setLastRefreshAttempt(state, action) {
      state.lastRefreshAttempt = action.payload;
    },
    incrementRefreshAttempts(state) {
      state.refreshAttempts += 1;
    },
  }
});

export const { 
  promptRequest, 
  promptSuccess, 
  promptFailure,
  setLastRefreshAttempt,
  incrementRefreshAttempts,
} = promptSlice.actions;

export const prompt = (payload) => async (dispatch, getState) => {
  const { input, id } = payload;
  dispatch(promptRequest());
  const token = localStorage.getItem('authToken');
  const isQuestion = getState().isQuestion;

  try {
    const response = await axios.post(
      isQuestion ? getQuestionApi() : getPromptApi(),
      isQuestion ? { input, conversation_id: id } : { input },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    
    dispatch(promptSuccess(response.data));

    // Immediate refresh to ensure busy state is set
    await dispatch(isQuestion ? questionMessages() : chatMessages());
    
    // Start polling for updates
    const pollInterval = setInterval(async () => {
      const refreshResponse = await dispatch(
        isQuestion ? questionMessages() : chatMessages()
      );
      
      const status = isQuestion ? 
        refreshResponse?.status?.question_busy : 
        refreshResponse?.status?.busy;
        
      if (!status) {
        clearInterval(pollInterval);
      }
    }, 2000);

    // Cleanup after 5 minutes to prevent infinite polling
    setTimeout(() => clearInterval(pollInterval), 300000);
    
  } catch (error) {
    dispatch(promptFailure(error.message));
    throw error;
  }
};

export default promptSlice.reducer;
