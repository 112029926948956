import { useEffect } from 'react';

export const useQAShortcut = ({ onSwitchToQA, onSwitchToDirector, isQuestion }) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.altKey) {
        if (event.key.toLowerCase() === 'q') {
          onSwitchToQA?.();
        } else if (event.key.toLowerCase() === 'd') {
          onSwitchToDirector?.();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onSwitchToQA, onSwitchToDirector]);
}; 