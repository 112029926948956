import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLogsApi } from '../Api';

const viewAnalysisSlice = createSlice({
  name: 'viewAnalysis',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    viewAnalysisRequest(state) {
      state.loading = true;
    },
    viewAnalysisSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    viewAnalysisFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { viewAnalysisRequest, viewAnalysisSuccess, viewAnalysisFailure } = viewAnalysisSlice.actions;

export const viewAnalysis = (payload) => async (dispatch) => {
  const {viewAnalysisPath} = payload || {}
  dispatch(viewAnalysisRequest());
  if(viewAnalysisPath){
    const token = localStorage.getItem('authToken');

    try {
      const viewAnalysisPathHtml = viewAnalysisPath.replace(/\.txt/g, '.html')
      const response = await axios.get(`${getLogsApi()}/organized-summary_${viewAnalysisPathHtml}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Convert file paths to endpoint URLs in the HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = response.data;
      tempDiv.querySelectorAll('img').forEach(img => {
        const src = img.getAttribute('src');
        if (src) {
          // Convert file path to endpoint URL if needed
          const isFilePath = src.startsWith('file://') || src.startsWith('/home/');
          if (isFilePath) {
            // Extract the relevant parts from the file path
            const parts = src.split('/');
            const chartIndex = parts.indexOf('charts');
            if (chartIndex !== -1) {
              const chartPath = parts.slice(chartIndex + 1).join('/');
              // Ensure path starts with /user-charts/
              const authSrc = `/user-charts/${chartPath}`;
              img.setAttribute('data-auth-src', authSrc);
              img.setAttribute('data-original-src', authSrc);
              img.removeAttribute('src');
            }
          } else if (src.includes('/user-charts/')) {
            // Ensure path starts with /user-charts/
            const authSrc = src.startsWith('/') ? src : `/user-charts/${src.split('/user-charts/')[1]}`;
            img.setAttribute('data-auth-src', authSrc);
            img.setAttribute('data-original-src', authSrc);
            img.removeAttribute('src');
          }
        }
      });
      
      dispatch(viewAnalysisSuccess(tempDiv.innerHTML));
    } catch (error) {
      dispatch(viewAnalysisFailure(error.message));
    }
  }
};

export default viewAnalysisSlice.reducer;
