import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import Home from "./components/Home";
import EnvironmentLogin from "./components/EnvironmentLogin";
import ViewResearch from "./components/ViewResearch";
import ViewAnalysis from "./components/ViewAnalysis";
import Plans from "./components/Plans";
import Logout from "./components/Logout";
import Help from './components/Help';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background text-text">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return isAuthenticated ? children : <Navigate to="/" replace />;
};

const App = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/home" replace /> : <EnvironmentLogin />} />
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/viewResearch" element={<ProtectedRoute><ViewResearch /></ProtectedRoute>} />
        <Route path="/viewAnalysis" element={<ProtectedRoute><ViewAnalysis /></ProtectedRoute>} />
        <Route path="/plans" element={<ProtectedRoute><Plans /></ProtectedRoute>} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/help" element={<Help />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
