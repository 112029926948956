import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaSignInAlt, FaUserCircle } from 'react-icons/fa';
import { AuthStateManager } from '../utils/authStateManager';

const EnvironmentLogin = () => {
  const { loginWithRedirect } = useAuth0();
  const [isHovered, setIsHovered] = useState(false);

  const handleLogin = async () => {
    try {
      await AuthStateManager.prepareForLogin();
      await loginWithRedirect({
        appState: { returnTo: '/home' },
        prompt: 'login',
        timeoutInSeconds: 60
      });
    } catch (error) {
      console.error('Login failed:', error);
      await AuthStateManager.cleanupAuth();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background text-text">
      <div className="bg-background-light p-8 rounded-lg shadow-lg animate-glow-pulse">
        <h1 className="text-4xl font-bold mb-6 font-display text-center">Welcome to <span className="text-secondary">ALICE</span></h1>
        <p className="mb-8 text-text-muted text-center">Revolutionizing productivity, one saved moment at a time.</p>
        
        <button
          onClick={handleLogin}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="w-full bg-primary text-text px-6 py-3 rounded-lg hover:bg-primary-dark transition-all duration-300 flex items-center justify-center font-semibold text-lg"
        >
          {isHovered ? <FaUserCircle className="mr-2" /> : <FaSignInAlt className="mr-2" />}
          Log In
        </button>
      </div>
    </div>
  );
};

export default EnvironmentLogin;
