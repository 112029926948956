import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApis } from '../Api';
import { handleAuthError } from '../utils/handleAuthError';

const questionMessagesSlice = createSlice({
  name: 'questionMessages',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    questionMessagesRequest(state) {
      state.loading = true;
    },
    questionMessagesSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    questionMessagesFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { questionMessagesRequest, questionMessagesSuccess, questionMessagesFailure } = questionMessagesSlice.actions;

export const questionMessages = () => async (dispatch) => {
  dispatch(questionMessagesRequest());
  const token = localStorage.getItem('authToken');
  try {
    // Get the latest API endpoints
    const { questionMessagesApi } = getApis();
    const response = await axios.get(questionMessagesApi, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    dispatch(questionMessagesSuccess(response.data));
  } catch (error) {
    if (!handleAuthError(error)) {
      dispatch(questionMessagesFailure(error.message));
    }
  }
};

export default questionMessagesSlice.reducer;
