import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { FaArrowUp, FaTrash } from 'react-icons/fa';
import { FiEdit2, FiPlus } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { chatMessages } from '../features/chatMessagesSlice';
import { questionMessages } from '../features/questionMessagesSlice';
import { questionMessagesEdit } from '../features/questionMessagesEditSlice';
import { prompt } from '../features/promptSlice';
import { setIsQuestion } from '../features/isQuestionSlice';
import { kill } from '../features/killSlice';
import { createQuestion } from '../features/createQuestionSlice';
import { deleteQuestion } from '../features/deleteQuestionSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { killQuestion } from '../features/killQuestionSlice';
import { SecureViewer } from '../utils/secureViewer';
import { useKillShortcut } from '../hooks/useKillShortcut';
import { useQAShortcut } from '../hooks/useQAShortcut';

const Chat = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const [loading] = useState(false);
  const [aliceBusy, setAliceBusy] = useState(false);
  const [killDone, setKillDone] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const [chats, setChats] = useState([]);
  const [editedChat, setEditedChat] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState('current');

  const [showOptions, setShowOptions] = useState(false);

  const [responseMessages, setResponseMessages] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);

  const isQuestion = useSelector(state => state.isQuestion);

  const [chatTitle, setChatTitle] = useState('')

  const conversationsRef = useRef(null);
  const [conversationsHeight, setConversationsHeight] = useState(0);

  useKillShortcut({
    isEnabled: aliceBusy && !killDone,
    isQuestion: isQuestion,
    onKill: () => setKillDone(true)
  });

  const handleEditClick = (index) => {
    if (chats?.[index]?.name) {
      setEditIndex(index);
      setEditedChat(chats[index].name);
    }
  };

  const handleSaveClick = async ({ id, name }) => {
    try {
      const trimmedName = name.trim();
      
      // Add validation for empty names and size limits
      if (!trimmedName) {
        throw new Error('Name cannot be empty');
      }
      
      // Enforce a reasonable maximum length for chat names (e.g. 100 characters)
      const MAX_NAME_LENGTH = 100;
      if (trimmedName.length > MAX_NAME_LENGTH) {
        throw new Error(`Name cannot be longer than ${MAX_NAME_LENGTH} characters`);
      }

      // Prevent names with excessive whitespace
      if (trimmedName.split(/\s+/).length > 10) {
        throw new Error('Name contains too many words');
      }

      const updatedChats = [...chats];
      const chatIndex = updatedChats.findIndex(chat => chat.id === id);
      
      if (chatIndex === -1) {
        throw new Error('Chat not found');
      }

      updatedChats[chatIndex] = {
        ...updatedChats[chatIndex],
        name: trimmedName
      };
      
      setChats(updatedChats);
      setEditIndex(null);
      setEditedChat('');

      await dispatch(questionMessagesEdit({ id, name: trimmedName }));
      
      await dispatch(questionMessages());
      
    } catch (error) {
      console.error('Failed to save chat name:', error);
      // Show error to user
      alert(error.message);
      dispatch(questionMessages());
    }
  };

  const { data: chatMessagesResponse } = useSelector(state => state.chatMessages) || {};
  const { data: questionMessagesResponse } = useSelector(state => state.questionMessages) || {};


  useEffect(() => {
    // Initial poll of both types to establish state
    const pollBoth = async () => {
      await Promise.all([
        dispatch(chatMessages()),
        dispatch(questionMessages())
      ]);
    };

    // Active type polling
    const pollActive = () => {
      if (isQuestion) {
        dispatch(questionMessages());
      } else {
        dispatch(chatMessages());
      }
    };

    // Initial poll
    pollBoth();

    // Set up interval for active type only
    const intervalId = setInterval(pollActive, 2000);

    // Cleanup
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, isQuestion]);

  useEffect(() => {
    const newBusyState = isQuestion ? 
      questionMessagesResponse?.status?.question_busy : 
      chatMessagesResponse?.status?.busy;
      
    setAliceBusy(!!newBusyState);
  }, [
    questionMessagesResponse?.status?.question_busy,
    chatMessagesResponse?.status?.busy,
    isQuestion
  ]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      // Consider "at bottom" if within 100px of the bottom
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
      setShouldAutoScroll(isAtBottom);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (conversationsRef.current) {
      const height = conversationsRef.current.getBoundingClientRect().height;
      setConversationsHeight(height);
    }
  }, [selectedChatId]);

  const getChatHeight = () => {
    if (selectedChatId === 'current') return '70vh';
    return `${window.innerHeight * 0.77 - conversationsHeight}px`;
  };

  useEffect(() => {
    setChats(questionMessagesResponse?.conversations);
    if(selectedChatId === 'current'){
      setResponseMessages(chatMessagesResponse?.history);
    }else{
      const { messages } = (chats || []).filter(chat => chat.id === selectedChatId)?.[0] || {};
      setResponseMessages(messages || []);
    }
    if (shouldAutoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [questionMessagesResponse?.conversations, chatMessagesResponse?.history, isQuestion, 
    selectedChatId, questionMessagesResponse?.status?.question_busy, chatMessagesResponse?.status?.busy, chats, shouldAutoScroll]);

  useEffect(() => {
    if (shouldAutoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [responseMessages, shouldAutoScroll]);

  const handleSendMessage = () => {
    if ((input || '').trim()) {
      dispatch(prompt({ input, id: selectedChatId }));
      setInput('');
    }
  };

  const handleChangeChats = (chatId, showOptionsVal = false) => {
    // Prevent unnecessary state updates if already in director mode
    if (chatId === 'current' && selectedChatId === 'current' && !isQuestion) {
      return;
    }

    // For director mode, ensure chat messages are initialized
    if (chatId === 'current') {
      if (!chatMessagesResponse?.history) {
        console.warn('Director mode chat history not initialized');
        // Trigger a refresh of chat messages
        dispatch(chatMessages());
      }
      dispatch(setIsQuestion(false));
      setResponseMessages(chatMessagesResponse?.history || []);
    } else {
      // For Q&A mode, ensure we have a valid chat
      const targetChat = (chats || []).find(chat => chat.id === chatId);
      if (!targetChat?.messages) {
        console.warn('Q&A chat not found or messages not initialized');
        return;
      }
      dispatch(setIsQuestion(true));
      setResponseMessages(targetChat.messages);
    }
    
    setSelectedChatId(chatId);
    setShowOptions(showOptionsVal);
  };

  const handleSwitchToQA = () => {
    if (chats?.[0]?.id) {
      handleChangeChats(chats[0].id, true);
    }
  };

  const handleSwitchToDirector = () => {
    // Ensure we're not already in director mode
    if (selectedChatId === 'current' && !isQuestion) {
      return;
    }
    handleChangeChats('current', false);
  };

  useQAShortcut({
    onSwitchToQA: handleSwitchToQA,
    onSwitchToDirector: handleSwitchToDirector,
    isQuestion
  });

  const handleDeleteClick = (chat, e) => {
    e.stopPropagation();
    setChatToDelete(chat);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (chatToDelete) {
      const success = await dispatch(deleteQuestion(chatToDelete.id));
      if (success) {
        handleChangeChats('current', true);
      }
    }
    setDeleteModalOpen(false);
    setChatToDelete(null);
  };

  const DeleteModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-background p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-text">Delete Conversation</h2>
        <p className="mb-6 text-text">Are you sure you want to delete this conversation? This action cannot be undone.</p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-background-lighter text-text rounded hover:bg-background-light"
            onClick={() => {
              setDeleteModalOpen(false);
              setChatToDelete(null);
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            onClick={handleConfirmDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  useImperativeHandle(ref, () => ({
    switchToQA: () => {
      const showOptionsVal = true;
      handleChangeChats(chats?.[0]?.id, showOptionsVal);
    },
    switchToCurrent: () => {
      handleChangeChats('current', false);
    }
  }));

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const timeString = date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: '2-digit'
    });
    return `${dateString} ${timeString}`;
  };

  const handleKill = () => {
    if (isQuestion) {
      dispatch(killQuestion());
    } else {
      dispatch(kill());
    }
    setKillDone(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChatTitle = (event) => {
    event.preventDefault();
    dispatch(createQuestion({name: chatTitle}))
    setIsModalOpen(false)
    setChatTitle('')
  }

  const handleOpenHtmlInNewWindow = (htmlContent) => {
    SecureViewer.createSecureFrame(htmlContent, 'Answer View');
  };

  useEffect(() => {
    // Poll both types when switching chat types
    const initializeChat = async () => {
      await Promise.all([
        dispatch(chatMessages()),
        dispatch(questionMessages())
      ]);
    };

    initializeChat();
  }, [selectedChatId, dispatch]); // Added dispatch to dependencies

  return (
    <div className="flex flex-col">
      {(
        <div 
          ref={conversationsRef}
          className="bg-background-light p-2 flex items-center justify-between"
        >
          <div className="flex items-center overflow-x-auto">
              {showOptions && 
              <>
              <button
              className="p-2 rounded mr-2 bg-background shadow cursor-pointer hover:bg-background-lighter transition-colors duration-200"
              onClick={() => {setIsModalOpen(true)}}
              >
                <FiPlus size={20} />
              </button>
              </>
              }
            {showOptions && (chats || []).map((chat, index) => (
              <div
                key={index}
                className={`p-2 rounded mr-2 cursor-pointer ${selectedChatId === chat.id ? 'bg-primary text-text' : 'bg-background text-text shadow'} transition-colors duration-200`}
                onClick={() => handleChangeChats(chat?.id, true)}
              >
                {index === editIndex ? (
                  <div>
                    <input
                      type="text"
                      value={editedChat}
                      onChange={(e) => setEditedChat(e.target.value)}
                      className="mr-2 bg-background-light text-text"
                      minLength={1}
                      required
                    />
                    <button
                      onClick={() => { handleSaveClick({ id: chat.id, name: editedChat }) }}
                      className="bg-primary text-text p-1 rounded hover:bg-primary-dark transition-colors duration-200"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    {chat?.name}
                    <FiEdit2
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(index);
                      }}
                      className="ml-2 cursor-pointer hover:text-primary"
                    />
                    <FaTrash
                      onClick={(e) => handleDeleteClick(chat, e)}
                      className="ml-2 cursor-pointer hover:text-red-600"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex">
            <div
              className={`p-2 rounded mr-2 cursor-pointer ${selectedChatId === 'current' ? 'bg-primary text-text' : 'bg-background text-text shadow'} transition-colors duration-200`}
              onClick={() => handleChangeChats('current', false)}
            >
              Director Chat
            </div>
            {
              <div className={`p-2 rounded mr-2 cursor-pointer ${selectedChatId !== 'current' ? 'bg-primary text-text' : 'bg-background text-text shadow'} transition-colors duration-200`}
                onClick={() => handleChangeChats(chats?.[0]?.id, true)}
              >
                QnA
              </div>
            }
          </div>
        </div>
      )}

      <div 
        className="flex-grow p-4 bg-background-lighter overflow-y-auto"
        style={{ height: getChatHeight() }}
        ref={messagesContainerRef}
      >
        {loading ? (
          <p>Loading...</p>
        ) : (
          (responseMessages || []).map((msg, index) => {
            const message = {
              text: msg.message,
              sender: msg.from === 'You' ? 'user' : 'alice',
              timestamp: msg.timestamp,
              id: index
            };

            return (
              <div
                key={message.id}
                className={`mb-2 p-2 rounded shadow max-w-md ${
                  message.sender === 'user' 
                    ? `${showOptions ? 'bg-secondary text-text' : 'bg-background text-text'} self-start` 
                    : 'bg-background-light text-text self-end'
                } transition-colors duration-200`}
                style={{ marginLeft: message.sender === 'alice' ? 'inherit' : 'auto' }}
              >
                <div className="text-xs text-text-muted mb-1">{formatTimestamp(message.timestamp)}</div>
                {selectedChatId !== 'current' && message.sender === 'alice' ? (
                  <button
                    onClick={() => handleOpenHtmlInNewWindow(message.text)}
                    className="bg-primary text-text font-bold py-2 px-4 rounded-lg transition-colors duration-200"
                  >
                    View Answer
                  </button>
                ) : message.sender === 'alice' && message.text?.includes('<') ? (
                  <div className="prose prose-sm max-w-none">
                    {message.text.split('\n').map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </div>
                ) : (
                  (message.text || '').split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))
                )}
              </div>
            );
          })
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4 bg-background-light flex items-center justify-between">
        <input
          type="text"
          value={input}
          onChange={(e) => !aliceBusy && setInput(e.target.value)}
          className={`flex-grow p-2 border rounded mr-2 bg-background text-text border-background-lighter ${
            aliceBusy ? 'cursor-not-allowed opacity-60' : ''
          }`}
          placeholder={aliceBusy ? 'Alice is Busy' : 'Type a message...'}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !aliceBusy) {
              handleSendMessage();
            }
          }}
          disabled={aliceBusy}
          style={{ pointerEvents: aliceBusy ? 'none' : 'auto' }}
        />
        {aliceBusy && (
          <button
            onClick={() => handleKill()}
            className={`p-2 ${killDone ? 'bg-background-lighter' : 'bg-red-600'} text-text rounded flex-shrink-0 mr-2 transition-colors duration-200 hover:bg-red-700`}
          >
            Kill
          </button>
        )}
        <button
          onClick={() => !aliceBusy && handleSendMessage()}
          className={`p-2 ${aliceBusy ? 'bg-background-lighter cursor-not-allowed' : 'bg-primary hover:bg-primary-dark'} text-text rounded flex-shrink-0 transition-colors duration-200`}
          disabled={aliceBusy}
        >
          <FaArrowUp />
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-background-lighter bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative bg-background p-4 rounded-lg shadow-lg text-text w-96">
            <button className="absolute top-2 right-2 text-text-muted hover:text-text" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2 className="text-2xl font-bold mb-4">New Chat</h2>
            <form onSubmit={handleChatTitle}>
              <div className="mb-4">
                <label className="block text-text text-sm font-bold mb-2" htmlFor="chatTitle">
                  Chat Title
                </label>
                <input
                  type="text"
                  id="chatTitle"
                  name="chatTitle"
                  className="w-full px-3 py-2 border rounded-lg bg-background-light text-text border-background-lighter"
                  placeholder="Enter title"
                  value={chatTitle}
                  onChange={(event) => setChatTitle(event.target.value)}
                />
              </div>
              <div className="flex justify-center">
                <button type="submit" className="mt-4 bg-primary text-text px-4 py-2 rounded-lg hover:bg-primary-dark transition-colors duration-200">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {deleteModalOpen && <DeleteModal />}
    </div>
  );
});

export default Chat;