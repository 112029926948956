import { Auth0Client } from "@auth0/auth0-spa-js";
import { AuthStateManager } from '../utils/authStateManager';

export const handleAuthError = async (error) => {
  if (error?.message?.includes('Network Error') || error?.response?.status === 401) {
    await AuthStateManager.cleanupAuth();
    
    // Initialize Auth0 client with your configuration
    const auth0Client = new Auth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.theaissist.com',
      cacheLocation: "localstorage"
    });

    try {
      // Use the same logout pattern as the Logout component
      await auth0Client.logout({
        logoutParams: {
          // Ensure we use the same origin as the app is running on
          returnTo: window.location.origin,
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
        }
      });
    } catch (logoutError) {
      console.error('Logout error:', logoutError);
      // If logout fails, wait briefly then redirect
      await new Promise(resolve => setTimeout(resolve, 100));
      window.location.href = window.location.origin;
    }
    return true;
  }
  return false;
}; 