import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getPlansApi } from "../Api";
import { getPlans } from './getPlanSlice';

const deletePlanSlice = createSlice({
  name: "deletePlan",
  initialState: {
    loading: false,
    error: "",
  },
  reducers: {
    deletePlanRequest(state) {
      state.loading = true;
    },
    deletePlanSuccess(state) {
      state.loading = false;
      state.error = "";
    },
    deletePlanFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deletePlanRequest, deletePlanSuccess, deletePlanFailure } =
  deletePlanSlice.actions;

export const deletePlan = (planTitle) => async (dispatch) => {
  dispatch(deletePlanRequest());
  const token = localStorage.getItem("authToken");

  try {
    await axios.delete(`${getPlansApi()}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        title: planTitle
      }
    });
    dispatch(deletePlanSuccess());
    dispatch(getPlans()); // Refresh plans after deletion
  } catch (error) {
    dispatch(deletePlanFailure(error.message));
    throw error;
  }
};

export default deletePlanSlice.reducer; 