import React, { useEffect, useState, useRef } from "react";
import { FaClipboardList, FaUserCircle, FaBook, FaCopy, FaSpinner, FaCompass, FaSignOutAlt } from "react-icons/fa";
import Chat from "./Chat";
import { tokens } from "../features/tokenSlice";
import { getPlans } from "../features/getPlanSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { viewResearch } from '../features/viewResearchSlice';
import { setNamePathSlice } from '../features/namePathSlice';
import { viewAnalysis } from '../features/viewAnalysisSlice';
import Navigator from './Navigator';
import Navbar from './Navbar';

const TokenDisplay = ({ data, loading, onCopy }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  
  const handleLogout = async () => {
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center gap-2">
        <div className="bg-background-lighter text-text font-medium px-4 py-2 rounded-lg shadow-md flex items-center">
          <FaSpinner className="animate-spin mr-2" />
          Loading credits...
        </div>
        <button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-700 text-white font-medium px-4 py-2 rounded-lg shadow-md flex items-center transition-colors duration-200"
        >
          <FaSignOutAlt className="mr-2" />
          Logout
        </button>
      </div>
    );
  }

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="flex items-center gap-2">
        <div className="bg-background-lighter text-text font-medium px-4 py-2 rounded-lg shadow-md flex items-center">
          Unavailable
        </div>
        <button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-700 text-white font-medium px-4 py-2 rounded-lg shadow-md flex items-center transition-colors duration-200"
        >
          <FaSignOutAlt className="mr-2" />
          Logout
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col gap-2">
        {Object.entries(data).map(([key, value]) => {
          const formattedValue = Number(value).toFixed(2);
          const displayName = key
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase())
            .trim();

          return (
            <div key={key} className="bg-background-lighter text-text font-medium px-4 py-2 rounded-lg shadow-md flex items-center">
              <span>
                {`${displayName} Credits - ${formattedValue}`}
              </span>
              <button
                className="ml-2 bg-background-lighter text-text p-1 rounded-lg hover:bg-background focus:outline-none focus:ring-2 focus:ring-background-lighter"
                onClick={() => onCopy(value)}
                aria-label={`Copy ${displayName} credits`}
              >
                <FaCopy />
              </button>
            </div>
          );
        })}
      </div>
      <button
        onClick={handleLogout}
        className="bg-red-600 hover:bg-red-700 text-white font-medium px-4 py-2 rounded-lg shadow-md flex items-center transition-colors duration-200 h-[42px]"
      >
        <FaSignOutAlt className="mr-2" />
        Logout
      </button>
    </div>
  );
};

const Home = () => {
  const { data, loading, initialized } = useSelector((state) => state.tokens);
  const { error: errorForPlans } = useSelector((state) => state.getPlans);
  const { isAuthenticated } = useAuth0();
  const [retryCount, setRetryCount] = useState(0);
  const { data: logResponse } = useSelector((state) => state.logs) || {};
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  const mainContentRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchTokens = async () => {
      if (isAuthenticated && (!initialized || !data?.theaissist)) {
        const success = await dispatch(tokens(true));
        if (!success && retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 2000);
        }
      }
    };

    fetchTokens();

    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        dispatch(tokens());
      }
    }, 30000); // Refresh every 30 seconds

    return () => {
      controller.abort();
      clearInterval(intervalId);
    };
  }, [dispatch, isAuthenticated, initialized, retryCount, data?.theaissist]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.altKey) {
        switch (e.code) {
          case 'KeyV':
            e.preventDefault();
            const activeLog = (logResponse || [])
              .filter(log => !log.name.toLowerCase().includes('summary'))
              .find(log => log.active);

            if (activeLog) {
              dispatch(setNamePathSlice({
                name: activeLog.name,
                path: activeLog.path
              }));
              dispatch(viewResearch({ viewResearchPath: activeLog.path }));
              navigate('/viewResearch');
            }
            break;

          case 'KeyA':
            e.preventDefault();
            const activeLogForAnalysis = (logResponse || [])
              .filter(log => !log.name.toLowerCase().includes('summary'))
              .find(log => log.active);

            if (activeLogForAnalysis) {
              dispatch(viewAnalysis({ viewAnalysisPath: activeLogForAnalysis.path }));
              navigate('/viewAnalysis');
            }
            break;

          case 'KeyB':
            e.preventDefault();
            dispatch(getPlans());
            if (!errorForPlans) {
              navigate("/plans");
            }
            break;

          case 'KeyH':
            e.preventDefault();
            navigate('/help');
            break;

          case 'KeyQ':
            e.preventDefault();
            chatRef.current?.switchToQA();
            break;

          case 'KeyD':
            e.preventDefault();
            chatRef.current?.switchToCurrent();
            break;

          case 'KeyN':
            e.preventDefault();
            setIsNavigatorOpen(prev => !prev);
            break;

          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [dispatch, navigate, logResponse, errorForPlans]);

  const handleCopyToken = (token) => {
    navigator.clipboard.writeText(token).then(
      () => alert("Token copied to clipboard!"),
      (err) => alert("Failed to copy token:", err)
    );
  };

  return (
    <div className="flex h-screen bg-background text-text">
      {/* Left Panel - Fixed width */}
      <Navbar />
      
      {/* Main Content - Flexible width */}
      <div className="flex-1 flex flex-col min-w-0">
        <div 
          className={`flex-1 flex flex-col transition-[margin] duration-300 ease-in-out ${
            isNavigatorOpen ? 'mr-96' : ''
          }`}
          ref={mainContentRef}
        >
          <header className="bg-background-light p-4 shadow-md flex justify-between items-center">
            {/* Left section - Plans */}
            <div className="flex items-center space-x-4">
              <button
                className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => {
                  dispatch(getPlans());
                  if (!errorForPlans) {
                    navigate("/plans");
                  }
                }}
              >
                <FaClipboardList className="mr-2" />
                Plans
              </button>
            </div>

            {/* Center section - Tokens */}
            <TokenDisplay
              data={data}
              loading={loading}
              onCopy={handleCopyToken}
            />

            {/* Right section - Assistant, Help and User */}
            <div className="flex items-center space-x-4">
              <button
                className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => setIsNavigatorOpen(true)}
              >
                <FaCompass className="mr-2" />
                Assistant
              </button>
              <button
                className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => navigate('/help')}
              >
                <FaBook className="mr-2" />
                Help
              </button>
              <div className="flex items-center">
                <FaUserCircle className="h-8 w-8 text-primary" />
              </div>
            </div>
          </header>
          <main className="flex-1 p-4 overflow-auto">
            <Chat ref={chatRef} />
          </main>
        </div>
      </div>

      {/* Assistant Panel - Fixed width, overlaid */}
      <Navigator 
        isOpen={isNavigatorOpen}
        onClose={() => setIsNavigatorOpen(false)}
      />
    </div>
  );
};

export default Home;