import {
  getBaseUrl,
  getLogsApi,
  getQuestionLogsApi,
  getKillApi,
  getMessagesApi,
  getQuestionMessagesApi,
  getPromptApi,
  getQuestionApi,
  getSummaryApi,
  getCreateQuestionApi,
  getTokensApi,
  getPlansApi,
  getKillQuestionApi,
  getNavigatorApi,
  getNavigatorMessagesApi
} from './config/ApiConfig';

// Export getApis as the primary way to get endpoints
export const getApis = () => ({
  baseUrl: getBaseUrl(),
  logsApi: getLogsApi(),
  questionLogsApi: getQuestionLogsApi(),
  killApi: getKillApi(),
  messagesApi: getMessagesApi(),
  questionMessagesApi: getQuestionMessagesApi(),
  promptApi: getPromptApi(),
  questionApi: getQuestionApi(),
  summaryApi: getSummaryApi(),
  createQuestionApi: getCreateQuestionApi(),
  tokensApi: getTokensApi(),
  plansApi: getPlansApi(),
  killQuestionApi: getKillQuestionApi(),
  navigatorApi: getNavigatorApi(),
  navigatorMessagesApi: getNavigatorMessagesApi()
});

// Export individual getters for direct access
export {
  getBaseUrl,
  getLogsApi,
  getQuestionLogsApi,
  getKillApi,
  getMessagesApi,
  getQuestionMessagesApi,
  getPromptApi,
  getQuestionApi,
  getSummaryApi,
  getCreateQuestionApi,
  getTokensApi,
  getPlansApi,
  getKillQuestionApi,
  getNavigatorApi,
  getNavigatorMessagesApi
};
