import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getSummaryApi } from '../Api';
import { handleAuthError } from '../utils/handleAuthError';

const summarySlice = createSlice({
  name: 'summary',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    summaryRequest(state) {
      state.loading = true;
    },
    summarySuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    summaryFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { summaryRequest, summarySuccess, summaryFailure } = summarySlice.actions;

export const summary = (payload) => async (dispatch) => {
  dispatch(summaryRequest());
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.post(getSummaryApi(), {
      input: payload.path
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    dispatch(summarySuccess(response.data));
  } catch (error) {
    if (!handleAuthError(error)) {
      dispatch(summaryFailure(error.message));
    }
  }
};

export default summarySlice.reducer;
