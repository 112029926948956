import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getPlansApi } from "../Api";
import { getPlans } from './getPlanSlice';

const postPlansSlice = createSlice({
  name: "postPlans",
  initialState: {
    loading: false,
    data: [],
    error: "",
  },
  reducers: {
    postPlansRequest(state) {
      state.loading = true;
    },
    postPlansSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    },
    postPlansFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const { postPlansRequest, postPlansSuccess, postPlansFailure } =
  postPlansSlice.actions;

export const postPlans = ({ res }) => async (dispatch) => {
  console.log("postPlans action called");
  const token = localStorage.getItem("authToken");
  console.log("Token retrieved:", token ? "Yes" : "No");

  let response = {};

  try {
    console.log("Attempting to make POST request");
    response = await axios.post(
      getPlansApi(),
      {
        ...res,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("POST request successful");
    dispatch(postPlansSuccess(response.data));
    
    // Dispatch getPlans action to update the local state
    dispatch(getPlans());
    
    return response.data; // Return the updated plan data
  } catch (error) {
    console.error("POST request failed:", error);
    dispatch(postPlansFailure(error.message));
    throw error; // Rethrow the error so we can catch it in the component
  }
};

export default postPlansSlice.reducer;
