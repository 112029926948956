import React, { useState, useEffect, useCallback } from 'react';
import { FaPause, FaChevronLeft, FaExpand } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { kill } from '../features/killSlice';
import { SecureViewer } from '../utils/secureViewer';
import { useKillShortcut } from '../hooks/useKillShortcut';
import { getBaseUrl } from '../config/ApiConfig';

const ViewAnalysis = () => {
  const { loading, data: viewAnalysisResponse } = useSelector(state => state.viewAnalysis) || {};
  console.log(viewAnalysisResponse);

  const iframeContent = viewAnalysisResponse;

  const navigate = useNavigate();

  const handleKill = () => {
    dispatch(kill());
    setKillDone(true);
  };

  const [viewerRef, setViewerRef] = useState(null);

  const openInNewTab = useCallback(() => {
    if (viewerRef) {
      viewerRef.cleanup();
      setViewerRef(null);
    }

    const viewer = SecureViewer.createSecureFrame(iframeContent, 'Analysis View');
    if (viewer) {
      setViewerRef(viewer);
    }
  }, [iframeContent, viewerRef]);

  useEffect(() => {
    return () => {
      if (viewerRef) {
        viewerRef.cleanup();
        setViewerRef(null);
      }
    };
  }, [viewerRef]);

  const handleScrollLeft = useCallback(() => {
    if (viewerRef) {
      viewerRef.cleanup();
      setViewerRef(null);
    }
    navigate('/home');
  }, [navigate, viewerRef]);

  const dispatch = useDispatch();
  const [killDone, setKillDone] = useState(false);

  useKillShortcut({
    isEnabled: !killDone,
    onKill: () => setKillDone(true)
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Full Screen View - Shift + F
      if (e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altKey && e.code === 'KeyF') {
        e.preventDefault();
        openInNewTab();
        return;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [openInNewTab]);

  const processContent = (content) => {
    // Pre-process image URLs to use data-auth-src
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    tempDiv.querySelectorAll('img').forEach(img => {
      const src = img.getAttribute('src');
      if (src) {
        // Convert file path to endpoint URL if needed
        const isFilePath = src.startsWith('file://') || src.startsWith('/home/');
        if (isFilePath) {
          // Extract the relevant parts from the file path
          const parts = src.split('/');
          const chartIndex = parts.indexOf('charts');
          if (chartIndex !== -1) {
            const chartPath = parts.slice(chartIndex + 1).join('/');
            img.setAttribute('data-auth-src', `/user-charts/${chartPath}`);
            img.removeAttribute('src');
          }
        } else if (src.includes('/user-charts/')) {
          img.setAttribute('data-auth-src', src);
          img.removeAttribute('src');
        }
      }
    });
    content = tempDiv.innerHTML;

    // Sanitize content
    const sanitizedContent = DOMPurify.sanitize(content, {
      FORBID_TAGS: ['iframe', 'object', 'embed'],
      FORBID_ATTR: ['onerror', 'onclick'],
      ADD_TAGS: ['script', 'img'],
      ADD_ATTR: ['data-auth-src', 'src']
    });

    // Post-process sanitized content to handle image authentication
    const postDiv = document.createElement('div');
    postDiv.innerHTML = sanitizedContent;
    postDiv.querySelectorAll('img').forEach(img => {
      const src = img.getAttribute('data-auth-src');
      if (src && src.includes('/user-charts/')) {
        // Store original src for reference
        img.setAttribute('data-original-src', src);
      }
    });

    return postDiv.innerHTML;
  };

  return (
    <div className="flex flex-col items-center p-6 bg-background text-text">
      <div className="flex justify-between w-full max-w-4xl mt-4">
        <button
          className="bg-primary hover:bg-primary-dark text-text font-bold py-2 px-4 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-primary"
          onClick={handleScrollLeft}
        >
          <FaChevronLeft className="mr-2" /> Back
        </button>
        <button
          className="bg-secondary hover:bg-secondary-dark text-text font-bold py-2 px-4 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-secondary"
          onClick={openInNewTab}
        >
          <FaExpand className="mr-2" /> Full Screen View
        </button>
      </div>
      <div
        className="w-full max-w-4xl border border-background-lighter p-4 mb-4 overflow-x-auto bg-gray-100 rounded-lg shadow-md"
        style={{ maxHeight: '700px' }} 
      >
        {loading ? (
          <p>Loading...</p>
        ) : (
          <iframe
            srcDoc={`
              <!DOCTYPE html>
              <html>
                <head>
                  <meta http-equiv="Content-Security-Policy" 
                        content="default-src 'self'; 
                                 style-src 'unsafe-inline' 'self' https://cdn.jsdelivr.net;
                                 script-src 'unsafe-inline' 'self' https://cdn.jsdelivr.net;
                                 font-src 'self' https://cdn.jsdelivr.net;
                                 img-src 'self' data: blob: ${window.location.origin} https: http:;
                                 connect-src *;">
                  <script src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"></script>
                  <script>
                    MathJax = {
                      tex: {
                        inlineMath: [['$', '$'], ['\\(', '\\)']],
                        displayMath: [['$$', '$$'], ['\\[', '\\]']],
                        processEscapes: true,
                        processEnvironments: true
                      },
                      options: {
                        skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre']
                      },
                      startup: {
                        ready: () => {
                          MathJax.startup.defaultReady();
                          
                          // After MathJax finishes rendering, store original LaTeX in data attributes
                          MathJax.typesetPromise().then(() => {
                            document.querySelectorAll('.MathJax').forEach(el => {
                              const originalMath = el.getAttribute('data-original');
                              if (originalMath) {
                                el.setAttribute('data-latex', originalMath);
                              }
                            });
                          });
                        }
                      }
                    };
                  </script>
                  <style>
                    body { 
                      margin: 0;
                      padding: 20px;
                      background-color: #f8f9fa;
                      font-family: Arial, sans-serif;
                      overflow-y: auto;
                    }
                    
                    img[data-auth-src] {
                      max-width: 100%;
                      height: auto;
                      display: block;
                      margin: 20px auto;
                    }
                    
                    .loading-spinner {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: 40px;
                      height: 40px;
                      border: 3px solid rgba(0,0,0,0.1);
                      border-radius: 50%;
                      border-top-color: #007bff;
                      animation: spin 1s linear infinite;
                    }
                    
                    @keyframes spin {
                      to { transform: translate(-50%, -50%) rotate(360deg); }
                    }
                    
                    .error-state {
                      border: 2px solid #dc3545;
                      padding: 10px;
                      border-radius: 4px;
                      color: #dc3545;
                      background: rgba(220, 53, 69, 0.1);
                      margin: 10px 0;
                      font-size: 14px;
                    }
                  </style>
                </head>
                <body>
                  ${processContent(iframeContent)}
                  <script>
                    (function() {
                      const authImages = document.querySelectorAll('img[data-auth-src]');
                      const token = '${localStorage.getItem('authToken')}';
                      const apiBaseUrl = '${getBaseUrl()}';
                      
                      authImages.forEach(img => {
                        // Create loading spinner
                        const spinner = document.createElement('div');
                        spinner.className = 'loading-spinner';
                        img.parentNode.appendChild(spinner);
                        
                        // Load image
                        const authSrc = img.getAttribute('data-auth-src');
                        const fullUrl = apiBaseUrl + authSrc;
                        const urlWithToken = fullUrl + (fullUrl.includes('?') ? '&' : '?') + 'token=' + token;
                        
                        fetch(urlWithToken, {
                          headers: {
                            'Authorization': 'Bearer ' + token
                          }
                        })
                        .then(response => {
                          if (!response.ok) throw new Error(response.status === 404 ? 'Image not found' : 'Failed to load image');
                          return response.blob();
                        })
                        .then(blob => {
                          const objectUrl = URL.createObjectURL(blob);
                          img.onload = () => {
                            spinner.remove();
                            img.classList.remove('image-loading');
                            URL.revokeObjectURL(img.src);
                          };
                          img.src = objectUrl;
                        })
                        .catch(error => {
                          console.error('Error loading image:', error);
                          spinner.remove();
                          const errorDiv = document.createElement('div');
                          errorDiv.className = 'error-state';
                          errorDiv.textContent = 'Failed to load image: ' + error.message;
                          img.parentNode.insertBefore(errorDiv, img.nextSibling);
                        });
                      });
                    })();
                  </script>
                </body>
              </html>
            `}
            className="w-full h-full"
            style={{ border: 'none', minHeight: '600px' }}
            title="Research Content"
            sandbox="allow-same-origin allow-scripts allow-popups allow-downloads"
          />
        )}
      </div>
      <div className="flex justify-between w-full max-w-4xl">
        <button
          className={`${
            killDone ? 'bg-background-lighter cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
          } text-text font-bold py-2 px-4 rounded-lg flex items-center transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-red-500`}
          onClick={handleKill}
          disabled={killDone}
        >
          <FaPause className="mr-2" />
          Kill
        </button>
      </div>
    </div>
  );
};

export default ViewAnalysis;
