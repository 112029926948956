export const STAGING_URL = 'https://alice-staging-febbdpfgekgwgtdx.canadacentral-01.azurewebsites.net';
export const PRODUCTION_URL = 'https://api.theaissist.com';

export const getEnvironmentConfig = (email) => {
  const isStaging = email?.trim().toLowerCase().endsWith('@theaissist.com');
  return {
    apiUrl: isStaging ? STAGING_URL : PRODUCTION_URL,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || (isStaging ? STAGING_URL : PRODUCTION_URL)
  };
};
