import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AuthStateManager } from '../utils/authStateManager';

const Logout = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const performLogout = async () => {
      try {
        // Clean auth state
        await AuthStateManager.cleanupAuth();

        // Perform Auth0 logout
        await logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
          onRedirectCallback: () => {
            // Ensure we only navigate if the component is still mounted
            if (isMounted) {
              navigate('/', { replace: true });
            }
          }
        });
      } catch (err) {
        if (isMounted) {
          setError(err);
          // If logout fails, redirect to home after 2 seconds
          setTimeout(() => navigate('/', { replace: true }), 2000);
        }
      }
    };

    performLogout();

    // Cleanup function to prevent state updates after unmount
    return () => {
      isMounted = false;
    };
  }, [logout, navigate]);

  if (error) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
          <h1 className="text-3xl font-bold text-red-600 mb-6">Logout Error</h1>
          <p className="text-xl mb-4">There was an error during logout.</p>
          <p className="text-gray-600">Redirecting you to safety...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-bold text-green-600 mb-6">Logging you out...</h1>
        <p className="text-xl">Please wait while we complete the logout process.</p>
      </div>
    </div>
  );
};

export default Logout;
