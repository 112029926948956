import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getKillQuestionApi } from '../Api';
import { handleAuthError } from '../utils/handleAuthError';

const killQuestionSlice = createSlice({
  name: 'killQuestion',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    killQuestionRequest(state) {
      state.loading = true;
    },
    killQuestionSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    killQuestionFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { killQuestionRequest, killQuestionSuccess, killQuestionFailure } = killQuestionSlice.actions;

export const killQuestion = (payload) => async (dispatch) => {
  dispatch(killQuestionRequest());
  const token = localStorage.getItem('authToken');
  try {
    const response = await axios.get(`${getKillQuestionApi()}?id=${payload}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    dispatch(killQuestionSuccess(response.data));
  } catch (error) {
    if (!handleAuthError(error)) {
      dispatch(killQuestionFailure(error.message));
    }
  }
};

export default killQuestionSlice.reducer;
