import React, { useState, useEffect } from "react";
import { FaHome, FaUserCircle, FaPause, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AnalysisCard from "./AnalysisCard";
import { postPlans } from "../features/postPlanSlice";
import { getPlans } from "../features/getPlanSlice";
import { kill } from '../features/killSlice';
import { chatMessages } from '../features/chatMessagesSlice';
import { useKillShortcut } from '../hooks/useKillShortcut';
import { deletePlan } from "../features/deletePlanSlice";

const Plans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [killDone, setKillDone] = useState(false);
  const { data: chatMessagesResponse } = useSelector(state => state.chatMessages) || {};
  const [aliceBusy, setAliceBusy] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);

  useKillShortcut({
    isEnabled: aliceBusy && !killDone,
    onKill: () => setKillDone(true)
  });

  // Add polling mechanism
  useEffect(() => {
    const pollStatus = () => {
      dispatch(chatMessages());
    };

    // Initial poll
    pollStatus();

    // Set up polling interval
    const intervalId = setInterval(pollStatus, 2000);

    // Cleanup
    return () => clearInterval(intervalId);
  }, [dispatch]);

  // Update aliceBusy state when chatMessagesResponse changes
  useEffect(() => {
    setAliceBusy(chatMessagesResponse?.status?.busy || false);
  }, [chatMessagesResponse?.status]);

  const handleHome = () => {
    navigate("/home");
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const { data: result } = useSelector((state) => state.getPlans);

  const extractSteps = (input) => {
    const stepsArray = (input || []).map((step) => step.trim());
    return stepsArray.map((step) => step.replace(/^Step \d+:\s*/, ""));
  };

  const [updatedSteps, setUpdatedSteps] = useState([]);
  const [updatedProgress, setUpdatedProgress] = useState("");
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [originalPlan, setOriginalPlan] = useState(null);

  useEffect(() => {
    if (result && result.length > 0) {
      const currentPlan = result[0]; // Assuming we're working with the first plan
      setOriginalPlan(currentPlan);
      setUpdatedTitle(currentPlan.title);
      setUpdatedProgress(currentPlan.progress.replace(/^Step /, ''));
      setUpdatedSteps(extractSteps(currentPlan.steps));
    }
  }, [result]);

  const isPlanModified = () => {
    if (!originalPlan) return false;
    
    const originalSteps = extractSteps(originalPlan.steps);
    const originalProgress = originalPlan.progress.replace(/^Step /, '');

    return (
      updatedTitle !== originalPlan.title ||
      updatedProgress !== originalProgress ||
      JSON.stringify(updatedSteps) !== JSON.stringify(originalSteps)
    );
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    console.log("updatedSteps:", updatedSteps);
    console.log("updatedProgress:", updatedProgress);

    if (isPlanModified() && updatedSteps?.length && updatedProgress) {
      console.log("Plan modified, preparing to dispatch");
      const updatedStepsWithStep = (updatedSteps || []).map(
        (step, index) => `Step ${index + 1}: ${step}`
      );

      console.log("Dispatching postPlans");
      try {
        await dispatch(
          postPlans({
            res: {
              plan: {
                id: originalPlan.id,
                progress: `Step ${updatedProgress}`,
                steps: updatedStepsWithStep,
                title: updatedTitle,
                timestamp: getCurrentTimestamp(),
              },
            },
          })
        ).unwrap(); // Use .unwrap() to handle the promise

        console.log("Plan updated successfully, refetching plans");
        await dispatch(getPlans()); // Refetch plans after successful update
      } catch (error) {
        console.error("Failed to update plan:", error);
        // You might want to show an error message to the user here
      }
    } else {
      console.log("No modifications or conditions not met for dispatch");
    }
    console.log("Navigating to /home");
    navigate("/home");
  };

  const handleKill = () => {
    dispatch(kill());
    setKillDone(true);
  };

  const handleDeleteClick = (plan) => {
    setPlanToDelete(plan);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deletePlan(planToDelete.title));
      setShowDeleteModal(false);
      setPlanToDelete(null);
    } catch (error) {
      console.error("Failed to delete plan:", error);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-background text-text">
      <header className="flex justify-between items-center p-4 bg-background-light">
        <div className="flex items-center space-x-4">
          <button
            className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
            onClick={handleHome}
          >
            <FaHome className="mr-2" />
            Home
          </button>
          {aliceBusy && (
            <button
              onClick={handleKill}
              className={`p-2 ${
                killDone ? 'bg-background-lighter cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
              } text-text rounded-lg flex items-center transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-red-500`}
              disabled={killDone}
            >
              <FaPause className="mr-2" />
              Kill
            </button>
          )}
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleSubmit}
            className="bg-green-600 hover:bg-green-700 text-text font-semibold px-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300"
          >
            Save
          </button>
          <FaUserCircle className="h-8 w-8 text-primary" />
        </div>
      </header>
      <main className="flex-grow p-6 overflow-auto bg-background">
        {(result || []).map((item, index) => (
          <div key={index} className="relative">
            <button
              onClick={() => handleDeleteClick(item)}
              className="absolute top-4 right-4 p-2 text-red-500 hover:text-red-700 transition-colors"
              title="Delete plan"
            >
              <FaTrash />
            </button>
            <AnalysisCard
              title={item.title}
              news={item.news}
              steps={extractSteps(item?.steps)}
              progress={item.progress}
              timestamp={item.timestamp}
              setUpdatedSteps={setUpdatedSteps}
              setUpdatedProgress={setUpdatedProgress}
              setUpdatedTitle={setUpdatedTitle}
            />
          </div>
        ))}
      </main>
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background-light p-6 rounded-lg shadow-xl max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
            <p className="mb-6">Are you sure you want to delete the plan "{planToDelete?.title}"?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-background-lighter text-text rounded-lg hover:bg-background-light transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plans;
