import { getBaseUrl } from '../config/ApiConfig';

// Constants for PDF generation
const A4_WIDTH_PX = 595.28; // A4 width in pixels at 72 DPI

// PDF generation utility
export const PDFRenderer = {
  generatePDF: async (content, title, latex = true) => {
    try {
      // Create a temporary container to measure and prepare content
      const prepContainer = document.createElement('div');
      prepContainer.style.width = `${A4_WIDTH_PX}px`;
      prepContainer.style.position = 'absolute';
      prepContainer.style.left = '-9999px';
      prepContainer.style.top = '0';
      prepContainer.innerHTML = content;
      document.body.appendChild(prepContainer);
      
      // Create rendering frame with dynamic height
      const renderFrame = document.createElement('iframe');
      Object.assign(renderFrame.style, {
        position: 'absolute',
        left: '-9999px',
        width: `${A4_WIDTH_PX}px`,
        height: 'auto',
        border: 'none',
        background: '#ffffff',
        visibility: 'hidden'
      });
      document.body.appendChild(renderFrame);

      try {
        const doc = renderFrame.contentDocument;
        doc.open();
        doc.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <title>${title}</title>
              <meta charset="UTF-8">
              <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Fira+Code:wght@400;500&display=swap" rel="stylesheet">
              <link href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/github.min.css" rel="stylesheet">
              <script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/highlight.min.js"></script>
              <script src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"></script>
            </head>
            <body>
              <div class="content-wrapper">${content}</div>
              <script>
                // Configure highlight.js
                window.hljs.configure({
                  languages: ['javascript', 'typescript', 'xml', 'css', 'html'],
                  ignoreUnescapedHTML: true,
                  cssSelector: 'pre code'
                });

                // Enhanced highlighting function
                function applyHighlighting() {
                  document.querySelectorAll('pre code').forEach((block) => {
                    // Force background color
                    block.style.backgroundColor = '#f6f8fa';
                    block.parentElement.style.backgroundColor = '#f6f8fa';
                    
                    // Detect language if not specified
                    if (!block.className.includes('language-')) {
                      const detectedLanguage = window.hljs.highlightAuto(block.textContent).language;
                      if (detectedLanguage) {
                        block.className = 'language-' + detectedLanguage + ' hljs';
                      }
                    }
                    
                    // Apply highlighting
                    window.hljs.highlightBlock(block);
                    
                    // Force computed styles
                    block.style.color = '#24292e';
                    block.querySelectorAll('*').forEach(el => {
                      if (el.className.includes('hljs-')) {
                        el.style.color = window.getComputedStyle(el).color;
                      }
                    });
                  });
                }

                // Initialize syntax highlighting
                applyHighlighting();
              </script>
            </body>
          </html>
        `);
        doc.close();

        // Wait for fonts and initial layout
        await document.fonts.ready;
        
        // Process images with proper sizing
        const images = doc.querySelectorAll('img[data-auth-src]');
        await Promise.all(Array.from(images).map(async (img) => {
          try {
            const authSrc = img.getAttribute('data-auth-src');
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${getBaseUrl()}${authSrc}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Image fetch failed');
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            return new Promise((resolve, reject) => {
              img.onload = () => {
                // Ensure image fits within page width
                if (img.naturalWidth > A4_WIDTH_PX - 40) {
                  img.style.width = '100%';
                  img.style.height = 'auto';
                }
                URL.revokeObjectURL(url);
                resolve();
              };
              img.onerror = () => {
                URL.revokeObjectURL(url);
                reject(new Error('Image load failed'));
              };
              img.src = url;
            });
          } catch (error) {
            console.error('Error loading image:', error);
            return Promise.resolve();
          }
        }));

        // Wait for MathJax and syntax highlighting with proper timing
        await new Promise((resolve) => {
          const checkRendering = () => {
            const mathJaxReady = renderFrame.contentWindow.MathJax?.typesetPromise;
            const highlightReady = renderFrame.contentWindow.hljs;
            
            if (mathJaxReady && highlightReady) {
              Promise.all([
                renderFrame.contentWindow.MathJax.typesetPromise([doc.body]),
                new Promise(r => {
                  renderFrame.contentWindow.applyHighlighting();
                  r();
                })
              ]).then(() => setTimeout(resolve, 1000)); // Extra time for rendering
            } else {
              setTimeout(checkRendering, 100);
            }
          };
          checkRendering();
        });

        // Add print-specific styles before rendering
        const printStyles = doc.createElement('style');
        printStyles.textContent = `
          @page {
            margin: 20mm;
            size: A4;
          }
          body {
            margin: 0;
            padding: 20mm;
            background: #ffffff;
          }
          .content-wrapper {
            max-width: ${A4_WIDTH_PX - 40}px;
            margin: 0 auto;
            padding: 0;
          }
          h1, h2, h3, h4, h5, h6 {
            margin-top: 1em;
            margin-bottom: 0.5em;
            page-break-after: avoid;
          }
          p {
            margin: 0.5em 0;
            orphans: 3;
            widows: 3;
          }
          pre, table, figure {
            page-break-inside: avoid;
            margin: 1em 0;
          }
          img {
            max-width: 100%;
            page-break-inside: avoid;
          }
        `;
        doc.head.appendChild(printStyles);

        // Get content element and prepare HTML
        const contentElement = doc.querySelector('.content-wrapper');
        
        // Process styles
        const processNode = (node) => {
          if (node.nodeType === 1) { // Element node
            const style = window.getComputedStyle(node);
            const important = ['color', 'background-color', 'font-family', 'font-size', 'padding'];
            important.forEach(prop => {
              node.style[prop] = style.getPropertyValue(prop);
            });
            
            // Process code blocks specifically
            if (node.tagName === 'PRE' || node.tagName === 'CODE') {
              node.style.backgroundColor = '#f6f8fa';
              node.style.color = '#24292e';
              if (node.tagName === 'PRE') {
                node.style.padding = '1em';
                node.style.margin = '1em 0';
                node.style.borderRadius = '6px';
                node.style.border = '1px solid #e1e4e8';
              }
            }
          }
        };

        // Process all nodes efficiently
        const walker = document.createTreeWalker(contentElement, NodeFilter.SHOW_ELEMENT);
        let node = walker.nextNode();
        while (node !== null) {
          processNode(node);
          node = walker.nextNode();
        }

        // Get the complete HTML content
        const htmlContent = `
          <!DOCTYPE html>
          <html>
            <head>
              <meta charset="UTF-8">
              <title>${title}</title>
              <style>${printStyles.textContent}</style>
            </head>
            <body>
              ${contentElement.outerHTML}
            </body>
          </html>
        `;

        try {
          // Get auth token
          const token = localStorage.getItem('authToken');
          if (!token) {
            throw new Error('Authentication token not found');
          }

          // Send HTML to API endpoint
          const response = await fetch(`${getBaseUrl()}/convert-to-pdf`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              html: htmlContent,
              latex: latex
            })
          });

          if (!response.ok) {
            throw new Error(`PDF conversion failed: ${response.statusText}`);
          }

          // Get the PDF blob
          const pdfBlob = await response.blob();

          // Download the PDF
          const downloadUrl = URL.createObjectURL(pdfBlob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = `${title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(downloadUrl);

        } catch (error) {
          console.error('Error generating PDF:', error);
          throw error;
        }

      } finally {
        document.body.removeChild(renderFrame);
        document.body.removeChild(prepContainer);
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }
}