import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getNavigatorMessagesApi } from '../Api';
import { handleAuthError } from '../utils/handleAuthError';

const navigatorMessagesSlice = createSlice({
  name: 'navigatorMessages',
  initialState: {
    loading: false,
    data: [],
    error: ''
  },
  reducers: {
    navigatorMessagesRequest(state) {
      state.loading = true;
    },
    navigatorMessagesSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    navigatorMessagesFailure(state, action) {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    }
  }
});

export const { navigatorMessagesRequest, navigatorMessagesSuccess, navigatorMessagesFailure } = navigatorMessagesSlice.actions;

export const navigatorMessages = () => async (dispatch) => {
  let response = [];
  dispatch(navigatorMessagesRequest());
  const token = localStorage.getItem('authToken');
  
  try {
    response = await axios.get(getNavigatorMessagesApi(), {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    dispatch(navigatorMessagesSuccess(response.data));
    return response.data;
  } catch (error) {
    if (!handleAuthError(error)) {
      dispatch(navigatorMessagesFailure(error.message));
    }
    return null;
  }
};

export default navigatorMessagesSlice.reducer; 